export default {
  methods: {
    ageFromBirthdate(birthdate) {
      var today = new Date()
      var birthDate = new Date(birthdate)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
      {
          age--
      }
      return age
    },
    formatThousandSeparatorNumber(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },

    ancienneteFromDate(lastDate) {
      const today = new Date()
      const daysDifference = parseInt((today.getTime() - new Date(lastDate).getTime()) / (1000 * 3600 * 24))
      if (daysDifference > 365) {
        const delai = parseInt(daysDifference / 365)
        if (delai === 1) {
          return `${delai} an`
        }
        else {
          return `${delai} ans`
        }
      }
      else if (daysDifference > 31) {
        const delai = parseInt(daysDifference / 31)
        return `${delai} mois`
      }
      else {
        if (daysDifference <= 1) {
          return `${daysDifference} jour`
        }
        else {
          return `${daysDifference} jours`
        }
      }
    },

    annonceElementSold(state) {
      switch (state) {
        case 'SOCIETE':
          return 'Société'
        case 'FDC':
          return 'Fonds de commerce'
      }
    },
    annonceTypeLocauxExploitation(state) {
      switch (state) {
        case 'PROPRIETARY':
          return 'Propriétaire des locaux'
        case 'BAILCOM':
          return 'Locataire des locaux au titre d’un bail commercial'
        case 'AUTREBAIL':
          return 'Locataire au titre d’un autre type de bail'
      }
    },
    caCategoryRender(state) {
      switch (state) {
        case "0_50":
          return "De 0€ à 50 K€"
        case "50_100":
          return "De 50 K€ à 100 K€"
        case "100_200":
          return "De 100 K€ à 200 K€​"
        case "200_500":
          return "De 200 K€ à 500 K€"
        case "500_1000":
          return "De 500 K€ à 1 M€"
        case "1000_2000":
          return "De 1 M€ à 2 M€"
        case "2000_5000":
          return "De 2 M€ à 5 M€"
        case "5000_10000":
          return "De 5 M€ à 10 M€"
        case "10000_":
          return "Supérieur à 10 M€"
        default:
          return "Non renseigné"
      }
    },
    procedureCollectiveRender(state) {
      switch (state) {
        case 'NON':
          return 'Aucune'
        case 'CONCILIATION':
          return 'Procédure de conciliation'
        case 'SAUVEGARDE':
          return 'Procédure de sauvegarde'
        case 'REDRESSEMENT':
          return 'Redressement judiciaire'
      }
    },
    priceCategoryRender(state) {
      switch (state) {
        case "0_50":
          return "De 0€ à 50 K€"
        case "50_100":
          return "De 50 K€ à 100 K€"
        case "100_150":
          return "De 100 K€ à 150 K€​"
        case "150_200":
          return "De 150 K€ à 200 K€​"
        case "200_300":
          return "De 200 K€ à 300 K€"
        case "300_500":
          return "De 300 K€ à 500 K€"
        case "500_750":
          return "De 500 K€ à 750 K€"
        case "750_1000":
          return "De 500 K€ à 1 M€"
        case "1000_2000":
          return "De 1 M€ à 2 M€"
        case "2000_5000":
          return "De 2 M€ à 5 M€"
        case "5000_10000":
          return "De 5 M€ à 10 M€"
        case "10000_":
          return "Supérieur à 10 M€"
        default:
          return "Non renseigné"
      }
    },
    qualiteRender(state) {
      switch (state) {
        case 'ASSOCIE':
          return 'Associé'
        case 'DIRIGEANT':
          return 'Dirigeant'
        case 'MANDATAIRE':
          return 'Mandataire'
      }
    },
    repreneurPersonalProject(state) {
      switch (state) {
        case 'RECONVERSION':
          return 'Reconversion professionnelle'
        case 'DEVELOPPER':
          return 'Dévlopper une activité existante'
        case 'SALARIE':
          return "Salarié de l'entreprise"
        case 'FAMILLE':
          return 'Membre de la famille du dirigeant'
        case 'AUTRE':
          return 'Autre'
      }
    },
  }
}
