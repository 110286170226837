<template>
  <div class="CompanyItem">

    <div class="row mt-4">
      <div class="col-12 col-md-6">
        <strong> Raison sociale </strong> : {{company.name}}
      </div>
      <div class="col-12 col-md-6">
        <strong> Siren </strong> : {{company.siren.match(/.{1,3}/g).join(' ')}}
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <strong> Capital </strong> : {{formatThousandSeparatorNumber(company.capital)}} €
      </div>
      <div class="col-12 col-md-6">
        <strong> Forme sociale </strong> : {{company.forme_sociale}}
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <strong> Date de création </strong> : {{$dayjs(company.date_creation).format('DD/MM/YYYY')}}
      </div>
      <div class="col-12 col-md-6">
        <strong> Procédure collective </strong> : {{procedureCollectiveRender(company.procedure_collective)}}
      </div>
    </div>

    <div class="row">
      <div v-if="company.nb_titres_capital" class="col-12">
        <strong> Nombre de titres de capital </strong> : {{company.nb_titres_capital}}
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <strong> Statut du cédant dans l'entreprise </strong> : {{qualiteRender(company.qualite)}}
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <strong> Adresse du siège social </strong> : {{company.address}}, {{company.zip_code}} {{company.city}}
      </div>
    </div>
  </div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'
export default {
  name: 'CompanyItem',
  props: {
    company: Object,
    default: function () {
      return {
        capital: 0,
        procedure_collective: "NON",
        qualite: "ASSOCIE",
        date_creation: "1900-01-01"
      }
    }
  },
  mixins: [
    renderMixins,
  ],
  mounted () {
    this.$dayjs.locale('fr')
  }
}
</script>
